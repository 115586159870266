.thumbnail {
  min-width: 164px;
  max-width: 295px;
}

@screen md {
  .thumbnail {
    min-width: 145px;
  }
}

@screen lg {
  .thumbnail {
    min-width: 160px;
  }
}

@screen xl {
  .thumbnail {
    min-width: 192px;
  }
}

.thumbnail-link::after {
  @apply rounded-b-lg;

  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.thumbnail-image::before {
  @apply rounded-t-lg overflow-hidden;

  content: ' ';
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  pointer-events: none;
  z-index: 2;
}

.thumbnail-image::after {
  @apply rounded-b-lg;

  content: ' ';
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
}

.bottom-gradient::after {
  @apply rounded-b;

  content: ' ';
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
}
