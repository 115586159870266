.nav-action-child {
  position: absolute;
  top: 34px;
  z-index: 1001;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #effef4;
  padding: 8px;
  white-space: nowrap;
  width: 248px;

  max-height: 66vh;
  overflow-y: auto;

  font-size: 0.875rem;

  /* DEFAULT TO HIDDEN */
  visibility: hidden;
  opacity: 0;
  transform: scale(0.95);
  transform-origin: top center;
  transition: all 0.3s;

  &.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  > ul {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
      position: relative;
      font-size: 0.75rem;

      > b {
        display: block;
        @apply text-black;
      }

      > * {
        display: block;
        padding: 12px 16px;
        border-radius: 4px;
        font-size: 0.75rem;
      }

      & button,
      & span,
      & a {
        transition: color 0.1s, background 0.1s;
        @apply text-black;

        & svg,
        & path {
          transition: fill 0.1s;
        }

        &:hover {
          @apply text-white bg-green-500;

          & svg:not(.dollar) path {
            fill: white;
          }
        }
      }

    /* Community section nested floating nav: */
    > ul {
      display: none !important;
      position: absolute;
      left: 109%;
      top: -5px;
      list-style: none;
      border-radius: 0 4px 4px 0;
      border-left: none;
    }

      &:hover {
        > ul {
          display: block !important;
        }
      }
    }
  }
}
