@media print {
  .print-hidden {
    display: none !important;
  }

  .print-label-container {
    margin: 0 !important;
    border: none !important;
    font-size: 8pt;
  }

  .print-label-title {
    font-size: 9pt;
  }
}
