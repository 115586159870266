.swap-print--container li {
  margin: 8px 0;
  padding-left: 24px;
}

.swap-print--container li::before {
  content: '✓';
  margin-left: -1.3em;
  margin-right: 8px;
  width: 1.3em;
}


.swap-print--container > div {
  max-width: 480px;
}

.swap-print--image {
  height: auto;
  width: 100%;
  max-width: 4in;
  max-height: 6in;
}

@media not print {
  .swap-print--left {
    @apply pb-10 md:pb-0 md:pr-16;
  }

  .swap-print--right {
    @apply pt-10 md:pt-0 md:pl-16 border-t border-dashed md:border-t-0 md:border-l border-grey-700;
  }

  .swap-print--container {
    @apply flex flex-col md:flex-row items-center justify-center space-y-4;
  }

  .swap-print--container--no-packing-slips {
    @apply flex justify-center;
  }

  .swap-print--label-no-packing-slips {
    height: auto;
    width: 100%;
    max-width: 4in;
    max-height: 6in;
  }
}

@media print {
  .page-break-after {
    break-after: page;
    break-inside: avoid;
  }

  .swap-print--button {
    display: none;
  }

  #launcher {
    display: none !important;
  }

  body {
    padding: 0;
  }

  .swap-print--container {
    @apply flex;
    padding: 0;
    transform: rotate(90deg) translateX(30%) scale(1.15);
    width: 100%;
    break-inside: avoid;
  }

  .swap-print--container > div {
    max-width: none;
    @apply flex items-center justify-center;
  }

  .swap-print--left {
    width: 5.5in;
    padding-right: .25in;
  }

  .swap-print--right {
    @apply border-l border-pastel-green-500;
    padding-left: .25in;
    width: 5.5in;
  }

  .swap-print--packing-slip {
    border: none;
    width: 4in;
  }

  .printing-help {
    display: none;
  }

  .swap-print--break {
    display: none;
  }

  .swap-print--container--no-packing-slips {
    break-inside: avoid;
  }

  .swap-print--label-no-packing-slips {
    margin: auto;
    height: 100vh;
    width: auto;
  }
}
