.markdown-container {
  @apply prose prose-sm max-w-none;
}

.markdown-container .table-container {
  overflow: auto !important;
  white-space: nowrap !important;
}

.markdown-container table {
  width: 100% !important;
}

.markdown-container tbody tr:nth-child(even) {
  background-color: #fff !important;
}

.markdown-container tbody tr:nth-child(odd) {
  background-color: #f8f8f8 !important;
}

.markdown-container thead tr {
  background-color: #4a4a4a !important;
  color: #fff !important;
}

.markdown-container thead td,
.markdown-container thead th {
  color: #fff !important;
  font-weight: 600 !important;
  position: sticky !important;
}

.markdown-container td,
.markdown-container th {
  padding: 6px 8px !important;
}
